<template>
  <div 
    class="FullWidthHorizontalScroll"
    @mouseleave="onMouseLeave()">

    <div class="sideFiller" />
    <slot />
    <div class="sideFiller" />
  </div>
</template>

<script>
export default {
    mounted() {
        // NOTE: Some event trickery to allow horizontal scroll with mouse-drag on desktop:
        this.$el.addEventListener('mousedown', this.onMousedown, true);
        this.$el.addEventListener('mouseup', this.onMouseup, true);
        this.$el.addEventListener('click', this.onClick, true);
    },
    beforeDestroy() {
        this.$el.removeEventListener('mousedown', this.onMousedown, true);
        this.$el.removeEventListener('mouseup', this.onMouseup, true);
        this.$el.removeEventListener('click', this.onClick, true);
    },
    methods: {
        onMousedown(e) {
            this.$el.addEventListener('mousemove', this.onMousemove);
            this.dragging = false;
            this.scrollLeft = this.$el.scrollLeft;
            this.x = e.clientX;
        },
        onMouseup(e) {
            this.$el.removeEventListener('mousemove', this.onMousemove);
        },
        onMousemove(e) {
            const deltaX = e.clientX - this.x;
            if (this.dragging) {
                this.$el.scrollLeft = this.scrollLeft - deltaX;
            } else {
                if (Math.abs(deltaX) > 10) {
                    this.dragging = true;
                }
            }
        },
        onClick(e) {
            if (this.dragging) {
                e.preventDefault();
                e.stopPropagation();
                this.dragging = false;
            }
        },
        onMouseLeave(){
            this.$el.removeEventListener('mousemove', this.onMousemove);
        },
    }
};
</script>

<style lang="scss" scoped>
.FullWidthHorizontalScroll {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-width: none;

    margin-left: calc(var(--widthWrapperSideWidth) * -1px);
    margin-right: calc(var(--widthWrapperSideWidth) * -1px);

    padding-top: 1em; // Allow box shaddow on elements inside
    padding-bottom: 1em;
}

.sideFiller {
    width: calc(var(--widthWrapperSideWidth) * 1px);
    flex-shrink: 0;
}
</style>
